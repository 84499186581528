:root {
  --red-color: #e21313;
  --gray-color: #f0f2f5;
  --dark-color: #e4e4e4;
  --white-color: #ffffff;
  --green-color: #015801;
  --orange-color: #d04702;
  --secondary-color: #fafafa;
  --blue-color: #0693e3;
  --dark-blue-color: #1a46ad;
  --dark-green-color: #033602;
  --dark-red-color: #ad1a1a;
  --yellow-color: #c98404;
}
.fixed-left {
  top: 0;
  left: 0;
  position: fixed;
  width: 54.16666667%;
}
@media screen and (max-width: 990px) {
  .fixed-left {
    display: none !important;
  }
}
.fixed-left .col-banners {
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.fixed-left .col-banners .ant-carousel {
  height: 100vh;
}
.fixed-left .col-banners .ant-carousel .slick-slider {
  height: 100vh;
}
.fixed-left .col-banners .ant-carousel .slick-slide {
  overflow: hidden;
  text-align: center;
  height: 100vh !important;
}
.fixed-right {
  top: 0;
  right: 0;
  height: 100%;
  position: fixed;
  width: 45.83333333%;
  background-color: var(--white-color) !important;
}
@media screen and (max-width: 990px) {
  .fixed-right {
    width: 100%;
  }
}
@media screen and (max-width: 690px) {
  .fixed-right .ant-col-18 {
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
}
.fixed-right .position-logo {
  right: 0;
  bottom: 20px;
  position: fixed;
  width: 45.83333333%;
}
@media screen and (max-width: 990px) {
  .fixed-right .position-logo {
    width: 100%;
  }
}
