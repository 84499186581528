label {
    margin-bottom: 2px;
    color: @opacity-85;
    display: inline-block;
}


.over-div {
    overflow: hidden;
}

.txt-overflow {
    .over-div();
    white-space: nowrap;
    text-overflow: ellipsis;
}

.text-center {
    text-align: center !important;
}

.text-right {
    text-align: right !important;
}

.f-18 {
    font-size: 18px !important;
}

.w-100 {
    width: 100% !important;
}

.m-0 {
    margin: 0 !important;
}

.m-t-0 {
    margin-top: 0 !important;
}

.m-t-2 {
    margin-top: 2px !important;
}

.m-t-4 {
    margin-top: 4px;
}

.m-t-5 {
    margin-top: 5px !important;
}

.m-t-8 {
    margin-top: 8px;
}

.m-t-12 {
    margin-top: 12px;
}

.m-t-16 {
    margin-top: 16px;
}

.m-t-22 {
    margin-top: 22px;
}

.m-t-25 {
    margin-top: 25px;
}

.m-r-0 {
    margin-right: 0;
}

.m-r-5 {
    margin-right: 5px;
}

.m-b-0 {
    margin-bottom: 0 !important;
}

.m-b-5 {
    margin-bottom: 5px !important;
}

.m-l-5 {
    margin-left: 5px !important;
}

.m-t-b-5 {
    margin: 5px 0 !important;
}

.m-t-b-8 {
    margin: 8px 0 !important;
}

.p-15 {
    padding: 15px;
}

.p-0 {
    padding: 0 !important;
}

.p-r-l-8 {
    padding: 0 8px;
}

.cursor-pointer {
    cursor: pointer;
}

.b-radius-2 {
    border-radius: 2px !important;
}

.hg-mobile {
    height: 32px !important;
}

button {
    .cursor-pointer();

    span {
        .cursor-pointer();
    }
}

.tag-processing {
    color: var(--white-color);
    background: @primary-color;
    border-color: @primary-color;
}

.tag-green {
    color: var(--white-color);
    background: var(--green-color);
    border-color: var(--green-color);
}

.vh100 {
    .over-div();
    height: 100vh !important;

    .ant-tag-green {
        .tag-green();
    }

    .ant-tag-processing {
        .tag-processing();
    }
}

.text-uppercase {
    text-transform: uppercase;
}

body {
    .ant-picker-small {
        padding: 2px 7px 1px;
    }

    .ant-input-number-handler-wrap {
        left: 0;
        right: auto;
    }

    h5 {
        &.ant-typography {
            .m-b-0();
        }
    }

    .space-white {
        white-space: normal;

        b {
            white-space: normal;
        }
    }

    .ant-avatar {
        padding: 2px;
        background-color: var(--white-color);
    }

    .ant-typography {
        h5 {
            .m-b-0();
        }
    }

    .ant-descriptions-row {
        .ant-descriptions-item-container {
            align-items: center;
        }
    }

    .demo-option-label-item {
        .ant-avatar {
            width: 18px;
            height: 18px;
            margin-right: 2px;
            line-height: 15px;
        }
    }

    .ant-divider-horizontal {
        margin: 5px 0;
    }

    .ant-form-small {
        .ant-form-item-label {
            >label {
                height: inherit;
            }
        }
    }

    .ant-input-sm {
        padding: 1px 7px;
    }

    .ant-input[disabled],
    .ant-input-number-disabled {
        color: rgba(0, 0, 0, 1) !important;
    }

    .ant-picker-calendar-header {
        justify-content: start;

        .ant-select,
        .ant-input-number {
            width: auto !important;
        }
    }

    .ant-modal {
        &.ant-modal-confirm {
            @media screen and (max-width: 765px) {
                width: 250px !important;
            }
        }

        @media screen and (max-width: 765px) {
            width: 95% !important;
        }

        .ant-modal-content {
            .ant-modal-close {
                color: var(--white-color);

                .ant-modal-close-x {
                    width: auto;
                    height: auto;
                    line-height: normal;
                    padding: 10px 15px !important;
                }
            }

            .ant-modal-header {
                color: var(--white-color);
                padding: 8px 15px !important;
                background: @primary-color !important;

                .ant-modal-title {
                    .text-uppercase();
                    color: var(--white-color);
                }
            }

            .ant-modal-body {
                .p-15();

                .tabela-pages {
                    .ant-table-tbody {
                        .ant-tag {
                            width: auto !important;

                            b {
                                width: auto !important;
                            }

                            &.w-100 {
                                .w-100();

                                b {
                                    .w-100();
                                }
                            }

                            &.w-75 {
                                width: 75px !important;

                                b {
                                    .w-100();
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .ant-tag-cyan {
        border-color: #14a3b3;
        color: var(--white-color);
        background-color: #14a3b3;
    }

    .ant-drawer-mask {
        width: 200%;
    }

    .ant-form-item-explain,
    .ant-form-item-extra {
        padding-top: 0;
        font-size: 10px;
        min-height: inherit;
    }

    .ant-form-item {
        .m-b-5();

        .anticon {
            .m-l-5();
        }

        .ant-input-search-button {
            .anticon {
                margin-left: 0 !important;
            }
        }
    }

    .ant-form-item-label {
        padding: 0 0 3px 0 !important;

        @media screen and (max-width: 765px) {
            padding: 0 0 1px 0 !important;
        }

        >label {
            line-height: 11px;
        }
    }

    .ant-menu-vertical .ant-menu-item,
    .ant-menu-vertical-left .ant-menu-item,
    .ant-menu-vertical-right .ant-menu-item,
    .ant-menu-inline .ant-menu-item,
    .ant-menu-vertical .ant-menu-submenu-title,
    .ant-menu-vertical-left .ant-menu-submenu-title,
    .ant-menu-vertical-right .ant-menu-submenu-title,
    .ant-menu-inline .ant-menu-submenu-title {
        .m-b-0();
        .m-t-0() !important;
    }

    .ant-tabs-tab {
        margin: 0 12px 0 12px !important;
    }

    .ant-alert-info {
        background-color: #e6f7ff;
        border: 1px solid #91d5ff;
    }

    .ant-radio-wrapper {
        white-space: normal !important;
    }

    .ant-list-pagination {
        .m-t-8();
    }

    .ant-list-sm {
        .ant-list-item {
            .p-0();
        }
    }

    .ant-pagination-prev,
    .ant-pagination-next,
    .ant-pagination-jump-prev,
    .ant-pagination-jump-next,
    .ant-pagination-item {
        .m-l-5();
        .m-r-0();
        height: 25px;
        min-width: 25px;
        line-height: 25px;
    }

    .ant-select-item-option-content {
        .txt-select {
            font-size: 11px;
            line-height: normal;

            .ant-tag {
                font-size: 11px;
                line-height: 18px;
            }
        }
    }

    .ant-select-multiple.ant-select-sm .ant-select-selection-item {
        .m-t-0();
        .m-b-0();
        height: 19px;
        line-height: normal;
    }

    .ant-picker-calendar {
        .ant-picker-panel {
            .ant-picker-body {
                .p-0();
            }
        }
    }

    .ant-picker-calendar-full {
        .ant-picker-panel {
            .ant-picker-calendar-date {
                .p-0();
                .m-0();
                border-left: 1px solid #f0f0f0;
                border-right: 1px solid #f0f0f0;
                height: 170px;
                overflow: hidden;
            }

            .ant-picker-calendar-date-content {
                height: 80%;
            }

            .ant-picker-calendar-date-value {
                padding: 0 5px;

                @media screen and (max-width: 1366px) {
                    line-height: 18px;
                }
            }

            .ant-picker-body {
                th {
                    padding: 5px;
                }
            }
        }
    }

    .ant-picker-content {
        thead {
            @media screen and (max-width: 765px) {
                display: none;
            }
        }

        th,
        td {
            @media screen and (max-width: 765px) {
                float: left;
                width: 33.333%;
                display: block;
                min-width: inherit;
            }

            @media screen and (max-width: 550px) {
                width: 50%;
            }
        }

        tbody {
            tr {
                &:last-of-type {
                    border-bottom: 2px solid #F0F0F0;
                }
            }
        }

        td {
            &:last-of-type {
                @media screen and (max-width: 765px) {
                    .w-100();
                }
            }
        }
    }

    .twitter-picker {
        .b-radius-2();
        border: 1px solid @border-color-base !important;
        box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px !important;

        >div {
            padding: 5px 7px 3px !important;

            span {
                >div {
                    border-radius: 50px !important;
                    margin: 0 3px 2px 0 !important;
                }
            }
        }

        #rc-editable-input-2 {
            @media screen and (max-width: 690px) {
                .b-radius-2();
                margin: 3px 0 !important;
                padding: 0 7px !important;
                width: calc(100% - 14px) !important;
                border: 1px solid @border-color-base !important;
                box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px !important;
            }
        }
    }

    .ant-modal-confirm-body {
        flex: 1;
        display: flex;
        .text-center();
        flex-direction: column;
        justify-content: center;

        >.anticon {
            .m-r-0();
            .m-b-5();
            float: inherit;
            font-size: 25px;
        }
    }

    .ant-modal-confirm-body>.anticon+.ant-modal-confirm-title+.ant-modal-confirm-content {
        margin-left: 0;
    }

    .ant-modal-confirm {
        .ant-modal-confirm-btns {
            .m-t-16();
            display: flex;
            float: inherit;
            justify-items: center;
            justify-content: center;
        }
    }
}

.ant-layout {
    background-color: var(--white-color) !important;

    &.site-layout {
        .p-r-l-8();
        background-color: var(--white-color);
        border-left: 1px solid @border-color-base;

        >.ant-layout-content {
            background-color: var(--white-color);
        }

        .ant-alert-info {
            background-color: #e6f7ff;
            border: 1px solid #91d5ff;
        }
    }
}

.ant-layout.ant-layout-has-sider>.ant-layout,
.ant-layout.ant-layout-has-sider>.ant-layout-content {
    overflow-x: hidden;
}

.ant-input,
input,
.ant-select-selection-item,
.ant-select-selection-placeholder {
    font-weight: 500;
}

.ant-picker {
    .w-100();
}

.ant-tabs-nav-list {
    >.ant-tabs-tab {
        padding: 9px !important;
        margin: 0 1px 0 0 !important;
    }
}

.ant-select,
.ant-input-number {
    .w-100();
}

.ant-input-number-input {
    .text-right();
}

.ant-dropdown-menu {
    .ant-btn-sm {
        .w-100();
        .text-right();
        padding: 2px 0;
        height: inherit;

        a {
            color: #3c3c3c;
        }
    }

    .ant-dropdown-menu-item,
    .ant-dropdown-menu-submenu-title {
        padding: 3px 12px;
        line-height: normal;
    }
}

.col-paginas {
    padding: 10px;

    &.calendar-recurso {
        .ant-picker-calendar-header {
            margin-top: -34px;
            padding: 5px 0 10px;
            justify-content: flex-end;

            @media screen and (max-width: 990px) {
                .m-t-0();
            }
        }

        .events {
            .ant-btn-sm {
                height: auto !important;
                padding: 5px !important;

                span {
                    .w-100();
                    order: inherit;
                    font-size: 11px;
                }
            }
        }
    }

    &.m-t-2 {
        .tabela-pages {
            margin-top: 24px;
        }
    }

    .ant-input-sm,
    .ant-input-affix-wrapper-sm {
        padding: 2px 7px;
    }

    .ant-select-multiple {
        &.ant-select-sm {
            .ant-select-selection-item {
                .m-b-0();
                .m-t-0();
                height: auto;
                line-height: normal;
                margin-inline-end: 2px;
                padding-inline-end: 4px;
                padding-inline-start: 4px;
            }
        }

        .ant-select-selection-item-content {
            .m-r-0();
        }

        .ant-select-selection-item-remove {
            >.anticon {
                vertical-align: sub;
            }

            .anticon-close {
                margin-left: 2px;
            }
        }

        .ant-select-selector {
            padding-right: 7px;
        }
    }
}

.ant-drawer {
    .ant-tag-green {
        .tag-green();
    }

    .ant-tag-processing {
        .tag-processing();
    }

    .btn-hd {
        top: -8px;
        z-index: 1;
        right: 4px;
        position: absolute;
    }

    .ant-drawer-header {
        color: var(--white-color);
        border-radius: 0 !important;
        border-bottom: 0 !important;
        padding: 8px 15px !important;
        background: @primary-color !important;

        .ant-drawer-title {
            .text-uppercase();
            color: var(--white-color);
        }

        .ant-drawer-close {
            right: -12px;
            position: absolute;
            color: var(--white-color);
            padding: 12px 15px !important;
        }
    }

    .ant-drawer-body {
        .p-15() !important;

        .ant-descriptions-header {
            .m-b-0();
        }

        .ant-descriptions-small {
            .ant-descriptions-row {
                >td {
                    padding-bottom: 0;
                }

                >th {
                    padding-bottom: 0 !important;
                }
            }
        }
    }

    .ant-drawer-footer {
        padding: 5px 15px;
    }

    .ant-drawer-content-wrapper {
        margin-top: 55px !important;
        height: calc(100% - 55px) !important;

        @media screen and (max-width: 1366px) {
            width: 70% !important;
        }

        @media screen and (max-width: 1200px) {
            width: 95% !important;
        }

        @media screen and (max-width: 992px) {
            .w-100();
        }
    }
}

span.ant-radio+* {
    padding-left: 5px !important;
    padding-right: 5px !important;
}

.ant-collapse {
    &.collapse-pages {
        &.col-ordem {
            .ant-collapse-extra {
                margin-top: -29px;

                @media screen and (max-width: 990px) {
                    margin-top: -29px !important;
                }

                @media screen and (max-width: 765px) {
                    .w-100();
                    .m-t-0();
                }
            }
        }

        >.ant-collapse-item {
            >.ant-collapse-header {
                .p-0();

                @media screen and (max-width: 990px) {
                    width: auto;
                    float: right;
                    margin-bottom: 8px;
                }

                @media screen and (max-width: 765px) {
                    .w-100();
                    float: inherit;
                }
            }
        }

        .ant-collapse-extra {
            margin-top: -36px;

            @media screen and (max-width: 990px) {
                .m-t-0();
            }

            @media screen and (max-width: 765px) {
                .w-100();
            }

            >button {
                z-index: 2;
                position: relative;

                @media screen and (max-width: 765px) {
                    .w-100();
                }
            }
        }

        .ant-collapse-content {
            @media screen and (max-width: 990px) {
                .w-100();
                float: left;
            }
        }

        .ant-collapse-content-box {
            margin-top: 10px;
            background-color: var(--gray-color);
            border: 1px solid @border-color-base;

            @media screen and (max-width: 765px) {
                padding: 10px !important;
            }
        }
    }

    &.collapse-calendar {
        right: 0;
        top: 0;
        z-index: 99;
        position: absolute;
        width:350px !important;
        .collapse-pages();

    }
}

.m-t-21 {
    margin-top: 21px;

    @media screen and (max-width: 765px) {
        margin-top: 20px;
    }
}

.top-mobile {
    .m-t-21();

    @media screen and (max-width: 765px) {
        margin-top: 4px;
        margin-bottom: 4px;
    }
}

.t-top {
    .m-t-21();

    @media screen and (max-width: 765px) {
        margin-top: 4px;
        margin-bottom: 4px;
    }
}

.col-w {
    @media screen and (max-width: 1200px) {
        flex: 0 0 100%;
        max-width: 100%;
    }

    @media screen and (max-width: 765px) {
        flex: 0 0 50%;
        max-width: 50%;
    }

    @media screen and (max-width: 572px) {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

.btn-col-21 {
    .m-t-21();

    @media screen and (max-width: 1200px) {
        margin-top: 4px;
    }

    @media screen and (max-width: 765px) {
        .m-t-21();

        .ant-col {
            flex: 0 0 50%;
            max-width: 50%;
        }
    }

    @media screen and (max-width: 572px) {
        margin-top: 4px;
        margin-bottom: 4px;
    }
}

.c-primary {
    color: @primary-color !important;
}

.c-red {
    color: var(--red-color) !important;
}

.c-green {
    color: var(--green-color) !important;
}

.c-blue {
    color: var(--blue-color) !important;
}

.c-dark-blue {
    color: var(--dark-blue-color) !important;
}

.c-dark-green {
    color: var(--dark-green-color)  !important;
}

.c-dark-red{
    color: var(--dark-red-color) !important;
}

.c-yellow {
    color: var(--yellow-color) !important;
}

.text-uppercase {
    text-transform: uppercase;
}

.tabela-pages {
    .m-t-16();

    &.mt-zero {
        .m-0();
    }

    .ant-divider-horizontal {
        &.ant-divider-with-text {
            border-top-color: @border-color-base;
        }
    }

    .ant-descriptions-header {
        .m-0();
    }

    .ant-descriptions-small .ant-descriptions-row>th,
    .ant-descriptions-small .ant-descriptions-row>td {
        .m-0();
    }

    .ant-table-tbody {
        >tr {
            >td {
                border-bottom: 1px solid @border-color-base !important;
            }
        }
    }

    .ant-table-small {
        .ant-table-selection-column {
            width: auto !important;
            min-width: auto !important;
        }

        .ant-table-thead {
            >tr {
                >th {
                    background-color: #f1f1f1;

                    &.ant-table-cell {
                        padding: 4px 5px !important;
                    }
                }
            }
        }

        .ant-table-tbody .ant-table-wrapper:only-child .ant-table {
            .m-0();
        }

        .ant-table-selection-column {
            width: auto !important;
            min-width: auto !important;
        }
    }

    .ant-table-footer {
        padding: 4px 8px 0 8px !important;
        background-color: var(--gray-color);
    }

    .ant-divider {
        margin: 5px 0 12px 0;
        border-top: 1px solid @border-color-base;
    }

    >.ant-table-wrapper {
        .m-t-8();
    }

    .ant-table-thead {
        background-color: var(--white-color);

        >tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
            background-color: transparent;
        }
    }

    .ant-table-container {
        border: 1px solid @border-color-base;
        border-bottom: 0;
    }

    table {
        span {
            .over-div();
            max-width: 100%;
            text-overflow: ellipsis;

            &.ant-divider-inner-text {
                .w-100();
            }

            &.ant-select-arrow {
                overflow: inherit !important;
            }
        }

        .avatar-part {
            width: 18px;
            height: 18px;
            line-height: 18px;
        }

        .ant-table-cell {
            .txt-overflow();
            line-height: 15px;

            &.ant-table-selection-column {
                overflow: inherit;
            }

            >div {
                .txt-overflow();
                justify-items: center;

                div {
                    .txt-overflow();
                    white-space: nowrap;
                }

                &.ant-table-selection {
                    overflow: inherit;

                    span {
                        overflow: inherit;
                    }
                }
            }

            .ant-form-item {
                .m-b-0();
            }
        }

        .ant-table-cell-content {
            div {
                .over-div();
                text-overflow: ellipsis;
            }
        }

        .ant-table-cell-ellipsis {
            div {
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .ant-btn-sm {
            line-height: 12px;

            @media screen and (max-width: 765px) {
                height: 24px !important;
                padding: 1.6px 0 !important;
            }
        }
    }

    .hide-header {
        .ant-table-thead {
            display: none !important;
        }
    }

    .ant-table {
        .ant-table-expanded-row-fixed {
            padding: 8px;
        }
    }

    .ant-table-tbody {
        .ant-table-cell-fix-left {
            .ant-tag {
                .w-100();

                b {
                    .w-100();
                }

                &.w-75 {
                    width: 75px !important;
                }
            }
        }

        .ant-tag {
            .m-r-0();
            .text-center();
            padding: 0 3px;
            display: inline-flex;

            b {
                .txt-overflow();
                font-weight: 500;
            }

            &.ant-tag-checkable {
                width: auto !important;

                b {
                    width: auto !important;
                }
            }

            &.tag-w-100 {
                .w-100();

                b {
                    .w-100();
                }
            }

            &.tag-small {
                font-size: 11px;
                line-height: 15px;
                .text-uppercase();
            }

            &.ant-tag-green {
                .tag-green();
            }

            &.ant-tag-processing {
                .tag-processing();
            }
        }

        >tr {
            >td {
                background-color: #f9f9f9;

                &.ant-table-cell {
                    padding: 4px 5px !important;
                }
            }

            &:nth-child(even) {
                >td {
                    background-color: white;
                }
            }

            label {
                height: auto !important;
            }

            .ant-form-item-label {
                .p-0();
            }

            &.ant-table-row {
                &:hover {
                    background-color: #f9f9f9 !important;

                    >td {
                        background-color: #f9f9f9 !important;
                    }
                }
            }

            &.ant-table-row-selected {
                &:hover {
                    >td {
                        background-color: #dbdbdb;
                    }
                }

                >td {
                    background-color: #d6d6d6 !important;
                    border-color: rgba(0, 0, 0, 0.1) !important;
                }
            }
        }
    }

    .ant-table-pagination {
        &.ant-pagination {
            margin: 8px 0 0 0 !important;
        }
    }
}

.i-Position {
    right: 0;
    top: -10px;
    position: absolute;
}

.events {
    .p-0();
    .m-0();
    list-style: none;

    .divCalendar {
        background: #3abec2;
        border-radius: 6px 6px 6px 6px;
        margin-top: 2px;
    }

    .ant-btn {
        .w-100();
        height: auto;
        display: flex;
        text-align: left;
        line-height: 12px;
        white-space: normal;
        align-items: center;
        vertical-align: middle;
        padding: 0 5px !important;

        &:hover,
        &:active,
        &:focus {
            span {
                text-decoration: underline;
            }
        }

        span {
            display: flex;
            order: 2;
            font-size: 11px;
            width: calc(100% - 23px);

            &.ant-avatar {
                .p-0();
                width: 35px;
                height: 35px;
                margin-right: 2px;
                line-height: normal;
                vertical-align: inherit;
            }
        }
    }
}

.ant-slider56 {
    font-size: 11px;
    display: inline-block;
    vertical-align: middle;
}

.card-dashboard {
    height: 350px;
    background-size: auto !important;
    background-repeat: no-repeat !important;
    background-position: right bottom !important;

    @media screen and (max-width: 1390px) {
        height: 305px;
        color: var(--white-color);
        background-size: 65% !important;
    }

    @media screen and (max-width: 1024px) {
        height: 222px;
    }
}

.reserva-localizacao {
    .ant-descriptions-small {
        .ant-descriptions-row {
            >td {
                padding-bottom: 5px;
            }
        }
    }
}

.breadcrumb {
    @media screen and (max-width: 992px) {
        .m-b-5();
    }
}

.auto-h {
    height: auto !important;
}

.hide {
    @media screen and (max-width:576px) {
        display: none;
    }
}

.h-45 {
    .ant-form-item-control {
        height: 45px;
    }
}

.t-mob-573 {
    .m-t-19();

    @media screen and (max-width: 572px) {
        .m-t-0();
    }
}

.m-t-19 {
    margin-top: 19px !important;
}

@media screen and (max-width: 992px) {
    .ant-input-search {
        .ant-input-group-addon {
            .ant-btn-sm {
                padding: 0 !important;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .mt12-mobile {
        .m-t-12();
    }

    .ant-table {
        .ant-btn-icon-only {
            &.ant-btn-sm {
                height: 24px !important;
                padding: 1.6px 0 !important;

                &.h32 {
                    .hg-mobile();
                }
            }
        }

        .ant-btn-sm {
            height: 24px !important;
            padding: 1.6px 5px !important;
        }
    }

    .ant-btn-sm {
        .hg-mobile();
        padding: 5.6px 15px !important;

        &.emp {
            .p-0();
            height: auto !important;
        }
    }

    .ant-select-single.ant-select-sm:not(.ant-select-customize-input):not(.ant-select-customize-input) .ant-select-selection-search-input {
        height: 100% !important;
    }

    .ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector::after,
    .ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item,
    .ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder {
        line-height: 32px !important;
    }

    .ant-input-sm,
    .ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector,
    .ant-picker-small,
    .ant-input-number-sm input,
    .CurrencyInput {
        .hg-mobile();
    }

    .ant-select-selector {
        min-height: 32px;
        height: auto !important;
    }

    .ant-input-number-input-wrap {
        input {
            height: 29px !important;
        }
    }

    .ant-form-small {
        .ant-form-item-control-input {
            min-height: 32px !important;
        }
    }

    .ant-radio-group-small {
        .ant-radio-button-wrapper {
            .hg-mobile();
            line-height: 32px !important;
        }
    }

    .ant-input-affix-wrapper-sm {
        padding: 0 7px !important;
    }

    .ant-select-multiple.ant-select-sm {
        .ant-select-selection-item {
            height: 22px !important;
            line-height: 18px !important;
        }
    }

    .ant-select-multiple {
        .ant-select-selection-item {
            margin-top: 2px !important;
            margin-bottom: 2px !important;
        }
    }

    .ant-input-search {
        .ant-input-group-addon {
            .ant-btn-sm {
                .p-0();
            }
        }
    }

    .mt8 {
        .m-t-8() !important;
    }

    .mt0 {
        margin-top: 0 !important;
    }

    .ant-divider-inner-text {
        .p-0();
        .w-100();
        text-align: left;
        white-space: normal;
    }
}

@media screen and (max-width: 690px) {
    .tabela-pages {
        >.ant-table-wrapper {
            overflow: hidden;
        }

        .ant-table-cell-fix-left {
            position: inherit !important;
        }

        .ant-table-thead>tr>th,
        .ant-table-tbody>tr>td,
        .ant-table tfoot>tr>th,
        .ant-table tfoot>tr>td {
            line-height: normal;
        }
    }
}

@media screen and (max-width: 572px) {
    .tt-8 {
        .m-t-8();
    }
}

.divDetalhes {
    padding: 15px 8px 15px 8px;
    margin: 0 8px 15px 0px;
    list-style: none;
    position: relative;
    border: 1px solid #e6e6e6;
    background: #fafafa;
}

.tituloDetalhe {
    color: rgb(15 15 15 / 72%);
    text-shadow: -1px -1px 0px #ffffff;
    padding-bottom: 5px;
}

.white > td {
    background: #ffffff !important;
}

.grey > td {
    background: #d1d1d1 !important;
}
.leaflet-container {
  width: 100%;
  height: 100vh;
  z-index: 99;
}
.padding10{
    padding: 10px;

    &.m-t-2 {
        .tabela-pages {
            margin-top: 24px;
        }
    }

    .ant-input-sm,
    .ant-input-affix-wrapper-sm {
        padding: 2px 7px;
    }

    .ant-select-multiple {
        &.ant-select-sm {
            .ant-select-selection-item {
                .m-b-0();
                .m-t-0();
                height: auto;
                line-height: normal;
                margin-inline-end: 2px;
                padding-inline-end: 4px;
                padding-inline-start: 4px;
            }
        }

        .ant-select-selection-item-content {
            .m-r-0();
        }

        .ant-select-selection-item-remove {
            >.anticon {
                vertical-align: sub;
            }

            .anticon-close {
                margin-left: 2px;
            }
        }

        .ant-select-selector {
            padding-right: 7px;
        }
    }
}
#divParticipantes div span{
    line-height: 1;
}



  .map-container {
    width: 100%;
    height: 400px; /* Ou outra altura desejada */
  }