.perfil {
    .ant-upload.ant-upload-select-picture-card {   
        width: 100%;
        margin-bottom: 0;
        overflow: hidden;
        margin-right: 0 !important;
    }
    .avatar-uploader {
        img {
            padding: 0;
        }
    }
    .ant-upload-list-picture .ant-upload-list-item, 
        .ant-upload-list-picture-card .ant-upload-list-item {
            padding: 0;
    }
    .ant-upload-list-picture-card-container {        
        margin: 0;
        width: 100%;
        height: 100%;
    }
    .ant-upload-list-picture-card .ant-upload-list-item,
        .ant-upload-list-picture .ant-upload-list-item {
            margin: 0;
    }
}
@primary-color: #0053a6;@link-color: #0053a6;@text-color: #3c3c3c;@font-size-base: 12px;@border-color-base: #dfdfdf;@disabled-color: rgba(0, 0, 0, 0.2);