header {
    .site-page-header {
        border: 1px solid @border-color-base;
    }
    &.ant-layout-header {
        z-index: 3;
        width: 100%;
        height: 55px;
        position: fixed;
        line-height: normal;
        padding: 5px 10px 0 8px;
        color: var(--white-color);
        background-color: @primary-color;
        .ant-avatar {
            padding: 0;
            margin-right: 10px;
            background-color: var(--white-color);
            > img {
                object-fit: contain;
            }
        } 
        .ant-badge-count {
            top: 12px;
            right: 9px;
            height: 15px;
            padding: 0 5px;
            font-size: 10px;
            min-width: auto;
            line-height: 15px;
        } 
        .ant-btn {
            color: var(--white-color);
            &.ant-btn-sm {
                @media screen and (max-width: 765px) {                
                    height: 24px !important;
                    padding: 1.6px 7px !important;
                    &.auto-h {
                        height: auto !important;
                    }
                }
            }
        }
        .ant-tag {
            width: 80px;
            margin-top: 2px;
            margin-right: 0;
            text-transform: uppercase;
        }
    }
}
.ant-dropdown-menu {
    &.col-notificacoes {
        width: 230px;
        max-height: 286px;
        min-height: 286px;
        padding: 8px 0 0 0 !important;
        .ant-dropdown-menu-item, 
            .ant-dropdown-menu-submenu-title {
            padding: 0;
            > a {
                padding: 10px 12px;
            }
        }
        .ant-menu-vertical {
            .ant-menu-item {
                padding: 0 8px;
            }
        }
        .ant-menu-item-divider, 
            .ant-dropdown-menu-item-divider {
            margin: 0;
        }
        > .ant-menu {
            height: 205px;
            overflow-x: hidden;
            &::-webkit-scrollbar {
                width: 4px;
            }
            &::-webkit-scrollbar-thumb {
                background-color: var(--gray-color);
                -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
                box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
            }
        }
        > li {
            padding: 0;        
            h4 {
                .ant-typography {
                    text-align: center;
                }
            }
            > a {     
                margin: 0; 
                width: 100%;   
                overflow: hidden;   
                text-align: center;
                white-space: nowrap;
                text-overflow: ellipsis;
                > span {
                    font-size: large;
                    padding-right: 5px;
                    vertical-align: middle;
                }
            }
            > button {
                bottom: 0;
                font-size: small;
                position: absolute;
            }
            h4 {
                > button {
                    top: -4px;
                    right: 0;
                    position: absolute;
                }
            }
        }
    }
}
.menu-notificacoes {
    width: 100%;
    text-align: center;
    bottom: 0 !important;
    position: absolute !important;
    border-top: 1px solid @border-color-base;
}
@primary-color: #0053a6;@link-color: #0053a6;@text-color: #3c3c3c;@font-size-base: 12px;@border-color-base: #dfdfdf;@disabled-color: rgba(0, 0, 0, 0.2);